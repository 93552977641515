<template>
	<div>
		<img :src="proxy.$axios.imgUrl + topImg" class="topImg-m" />
		<div class="main">
			<div class="content-title" v-if="brief">
				{{brief}}
			</div>
			<div class="content-right-firstFloor" v-for="(item1,index1) in list" :key="index1">
				<div class="firstFloor-title">
					{{item1.title}}
				</div>
				<div class="content-right-secondFloor">
					<div class="secondFloor-item flex" v-for="(item2,index2) in item1?.child" :key="index2">
						<div class="secondFloor-title">
							{{item2.title}}
						</div>
						<ul class="content-right-thirdFloor">
							<li v-for="(item3,index3) in item2?.child" :key="index3">
								{{item3.title}}
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
	import {
		onMounted,
		ref,
		getCurrentInstance,
	} from 'vue';
	import {
		useRoute
	} from 'vue-router'
	import EventBus from "../../assets/common/event-bus.js"

	const router = useRoute()
	const {
		proxy
	} = getCurrentInstance()

	onMounted(() => {
		EventBus.emit("mgheadr", router.query.type);
		getTopimg()
		getmenus()
	})

	const typeTitle = ref([{
			title: 'Services',
			type: 2
		},
		{
			title: 'SAIFU Solutions',
			type: 3
		},
	])

	/**
	 * 左侧菜单
	 */
	const menusTitle = ref('')

	const menusAcId = ref('')

	const menuList = ref([])

	async function getmenus() {
		let res = await proxy.$axios.get(router.query.type == 2 ? '/api/index/getServicesData' :
			'/api/index/getSolutionsData', {
				pid: 0
			})
		menuList.value = res.data
		menusChange(router.query.id)
	}

	const list = ref({})

	async function menusChange(id) {
		menusAcId.value = id ? id : menuList.value[0].id
		menusTitle.value = typeTitle.value.find(v => v.type == router.query.type).title
		if (id) {
			brief.value = menuList.value.find(v => v.id == id).brief
		} else {
			brief.value = menuList.value[0].brief
		}

		let res = await proxy.$axios.get(router.query.type == 2 ? '/api/index/getServicesData' :
			'/api/index/getSolutionsData', {
				pid: id ? id : menuList.value[0].id
			})
		list.value = res.data
	}
	/**
	 * 右侧头部文字
	 */
	const brief = ref('')

	const topImg = ref('')

	async function getTopimg() {
		let res = await proxy.$axios.get('/api/index/getTopImg', {
			type: router.query.type == 2 ? 'services' : 'solutions'
		})
		topImg.value = res.data.top_img
	}
</script>

<style scoped>
	body {
		padding-bottom: 0.4rem;
	}

	.topImg-m {
		width: 100%;
		height: auto;
		display: block;
	}

	.content-title {
		font-weight: 400;
		font-size: 0.24rem;
		color: #333333;
		line-height: 0.34rem;
		margin-top: 0.33rem;
	}

	.content-right-firstFloor {
		margin-top: 0.48rem;
		padding-bottom: 0.28rem;
		border-bottom: 1px solid #E6E6E6;
		font-weight: bold;
		font-size: 0.32rem;
		color: #000000;
		line-height: 0.32rem;
	}

	.firstFloor-title {
		padding-bottom: 0.44rem;
		border-bottom: 1px solid #E6E6E6;
		width: 100%;
	}

	.content-right-secondFloor {
		display: grid;
		grid-template-columns: repeat(1, 100%);
		grid-gap: 0 0.34rem;
		grid-auto-rows: auto;
	}

	.secondFloor-item {
		font-size: 0.28rem;
		line-height: 0.34rem;
		padding: 0.34rem 0;

	}

	.secondFloor-title {
		font-weight: 500;
		width: 2.6rem;
	}

	.content-right-thirdFloor {
		margin-left: 0.47rem;
		width: calc(100% - 3.07rem);
		word-break:keep-all;
	}

	.content-right-thirdFloor>li {
		list-style-type: disc;
		margin-bottom: 0.23rem;
		font-weight: 400;
		font-size: 0.24rem;
		line-height: 0.3rem;
		color: #666666;
	}
</style>